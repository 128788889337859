import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CropperComponent } from 'angular-cropperjs';
import { BlobService } from 'src/app/services/blob.service';

@Component({
  selector: 'app-upload-gym-image-modal',
  templateUrl: './upload-gym-image-modal.component.html',
  styleUrls: ['./upload-gym-image-modal.component.scss']
})
export class UploadGymImageModalComponent implements OnInit {
  #sas: string | undefined;
  loading = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string, aspectRatio?: number }, private dialogRef: MatDialogRef<UploadGymImageModalComponent>, private blobService: BlobService) {
    if (this.data.aspectRatio) {
      this.cropperOptions.aspectRatio = this.data.aspectRatio;
    }
  }

  ngOnInit(): void {
    this.blobService.getAvatarSas().subscribe(sas => this.#sas = sas);
  }

  image: string | undefined;
  cropperOptions = {
    aspectRatio: 1,
    zoomTo:0.5,
    viewMode:1,
    minCropBoxHeight:10,
    minCropBoxWidth:10,
    background:false,
    responsive:true,
    autoCropArea:1,
    checkOrientation:false,
    guides:true
  }

  @ViewChild("hiddenFileInput")
  hiddenFileInput: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('cropper')
  cropper: CropperComponent | undefined;

  uploadImage() {
    this.hiddenFileInput?.nativeElement.click();
  }

  handleImageUpload(event: any) {
    const fileUploaded = event.target.files[0] as File;
    this.image = URL.createObjectURL(fileUploaded);
  } 

  getCropData() {
    const c = this.cropper?.cropper;
    if (c) {
      this.loading = true;
      this.image = c.getCroppedCanvas().toDataURL() ?? "";
      const data = c.getCanvasData();
      c.scale(300 / data.width, 300 / data.width);
      c.getCroppedCanvas().toBlob(blob => {
        if (blob && this.#sas) {
          this.blobService.uploadGymImage(blob).subscribe((x: any) => this.dialogRef.close(x.key));
        }
      });
    }
  }
}
