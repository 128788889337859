<div class="fixed flex flex-col z-40 top-0 left-0 w-72 h-full border-r bg-white select-none">
    <div
        class="font-poppins font-medium mt-8 flex flex-none justify-center items-center gap-2 text-2xl text-primary-700 border-b pb-6 mb-5">
        <div class="flex items-center gap-2">
            <img class="w-10 h-10" src="/assets/images/logo.svg"/> FightCloud
        </div>
        <div class="ml-8 text-base font-inter font-normal">
            <svg (click)="collapseClick.emit()"  class="cursor-pointer focus:outline-0"
                width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.91232 1V19M3.15567 1H16.304C17.3413 1 18.1823 1.89543 18.1823 3V17C18.1823 18.1046 17.3413 19 16.304 19H3.15567C2.1183 19 1.27734 18.1046 1.27734 17V3C1.27734 1.89543 2.1183 1 3.15567 1Z"
                    stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>
    <div class="grow">
        <div class="flex flex-col justify-between h-full">
            <div class="mx-4">
                <a routerLink="/" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                    <div class="font-medium flex items-center gap-4">
                        <home-outline-icon [size]="20"></home-outline-icon>
                        <span>Calendar</span>
                    </div>
                </a>
                <a routerLink="/session/create" routerLinkActive="bg-primary-50 text-primary-700"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                    <div class="font-medium flex items-center gap-4">
                        <calendar-outline-icon [size]="20"></calendar-outline-icon>
                        <span>Sessions</span>
                    </div>
                </a>
                <a routerLink="/student" routerLinkActive="bg-primary-50 text-primary-700"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                    <div class="font-medium flex items-center gap-4">
                        <users-outline-icon [size]="20"></users-outline-icon>
                        <span>Students</span>
                    </div>
                </a>
            </div>
            <div>
                <div class="mx-4">
                    <a [href]="checkinUrl" target="_blank"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center gap-4">
                            <check-circle-outline-icon [size]="20"></check-circle-outline-icon>
                            <span>Attendance</span>
                        </div>
                    </a>
                </div>
                <div class="mx-4">
                    <a [href]="studentPortalUrl" target="_blank"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center gap-4">
                            <user-group-outline-icon [size]="20"></user-group-outline-icon>
                            <span>Student portal</span>
                        </div>
                    </a>
                </div>
                <div class="mx-4">
                    <a [href]="kioskUrl" target="_blank"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center gap-4">
                            <book-open-outline-icon  [size]="20"></book-open-outline-icon>
                            <span>Registration kiosk</span>
                        </div>
                    </a>
                </div>
                <div class="pb-5 border-b mx-4">
                    <a routerLink="/settings" routerLinkActive="bg-primary-50 text-primary-700"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center gap-4">
                            <cog-6-tooth-outline-icon [size]="20"></cog-6-tooth-outline-icon>
                            <span>Settings</span>
                        </div>
                    </a>
                </div>
                <div *ngIf="user$ | async; let user">
                    <div class="flex my-6">
                        <button class="font-medium grow border rounded-lg py-2 text-center mx-4">{{user.gymName}}</button>
                    </div>
                    <div class="flex gap-2 mx-4 mb-8 items-center">
                        <app-avatar [initials]="user.name?.charAt(0)?.toUpperCase() ?? ''"></app-avatar>
                        <div  class="min-w-0 font-medium flex flex-col">
                            <span>{{user.name}}</span>
                            <span class="overflow-hidden text-ellipsis whitespace-nowrap text-gray-500">{{user.email}}</span>
                        </div>
                        <arrow-up-tray-outline-icon (click)="logout()" [size]="20" class="h-5 w-6 ml-6 heroicon-sw-1.5 cursor-pointer rotate-90 text-gray-500 font-medium"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>